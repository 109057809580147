<template>
  <div class="full">
    <div class="order_nav d_f ali_c">
      <div class="left_icon d_f ali_c">
        <img src="../img/black_back.png" alt="" class="cur_p" @click="goInner">
        <div class="cut"></div>
        <img src="../img/back_home.png" alt="" class="cur_p back_home" @click="$router.push('/')">
      </div>
      <span>支付订单</span>
    </div>
    <div class="ps_content">
      <div class="t_a">
        <img src="../img/ps_success.png" alt="">
        <div class="tip">支付成功</div>
        <div class="sub_tip">等待酒店确认~</div>
      </div>
      <div class="btn d_f ali_c j_s t_a">
        <div class="cur_p" @click="orderDetail">订单详情</div>
        <div class="ac cur_p" @click="$router.push('/hotels')">再次购买</div>
      </div>
    </div>
    <div class="tip_out">
      <div class="title">预订说明</div>
      <div class="item">1.订单短信将发送至{{phone}}，您可以点击“订单详情”,关注订单处理进度；</div>
      <div class="item">2.订单需等待酒店确认后才生效，请在订单生效后再前往酒店办理入住；</div>
      <div class="item">3.订单不支持部分退款。</div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "hotelPaySuccess",
    data() {
      return {
        phone:this.$route.query.phone,
        sellerId:this.$route.query.sellerId,
        orderNumber:this.$route.query.orderNumber,
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {
      goInner(){
        this.$router.push({
          path:'/hotelInner',
          query:{
            sellerId:this.sellerId,
          }
        })

      },
      orderDetail(){
        this.$router.push({
          path:'/hotelOrderDetail',
          query:{
            orderNumber:this.$route.query.orderNumber,
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .left_icon{
    .cut{
      width: 1px;
      height: 19px;
      background: #3E3E3E;
      border-radius: 1px;
      margin: 0 11px;
    }
    .back_home{
      width: 18px;
      height: 19px;
    }
  }
  .tip_out{
    .title{
      font-size:14px;
      margin-bottom: 25px;
    }
    .item{
      margin-bottom: 20px;
    }
    font-size:12px;
    font-weight:bold;
    color:rgba(62,62,62,1);
    line-height:17px;
    padding: 24px 21px;
  }
  .ps_content{
    .btn{
      div{
        width: 118px;
        cursor: pointer;
        border:1px solid rgba(29,223,80,1);
        border-radius:3px;
        height:35px;
        line-height: 35px;
        color: rgba(29,223,80,1);
        &.ac{
          background:linear-gradient(35deg,rgba(29,223,80,1),rgba(99,220,79,1));
          color: white;
        }
      }
    }
    .sub_tip{
      font-size:13px;
      color:rgba(110,110,110,1);
      margin-bottom: 42px;
    }
    .tip{
      font-size:17px;
      color:rgba(62,62,62,1);
      margin-bottom: 15px;
    }
    img{
      width: 81px;
      margin-bottom: 18px;
    }
    font-weight:500;
    padding: 60px 60px 39px 60px;
    background-color: #f0f0f0;
  }
  .order_nav{
    background: #F0F0F0;
    span{
      color: #3E3E3E!important;
      padding: 0 30px 0 0;
    }
  }
</style>
